<template>
  <div class="border rounded p-1" :class="{ disabled }">
    <b-badge
      v-for="(tag, index) in tags"
      :key="index"
      variant="dark"
      class="m-1"
    >
      {{ tag }}
      <button class="btn btn-xs p-0" @click="handleClickRemoveTag(index)">
        <i class="fa fa-remove text-white" />
      </button>
    </b-badge>
    <b-input
      type="text"
      class="border-0"
      maxlength="20"
      placeholder="輸入標籤"
      :disabled="disabled"
      v-model="inputTag"
      @keyup.enter="handleEnterInputTag"
      @keyup="handleKeyUpInputTag"
      @blur="handleEnterInputTag"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      tags: [],
      inputTag: null,
    }
  },
  watch: {
    value () {
      this.$set(this, 'tags', this.value)
    }
  },
  mounted() {
    this.$set(this, 'tags', this.value)
  },
  methods: {
    handleClickRemoveTag(index) {
      if (this.disabled) {
        return;
      }
      this.tags.splice(index, 1);
    },
    handleKeyUpInputTag(event) {
      if (event.key == ",") {
        this.inputTag = this.inputTag.slice(0, -1);
        this.handleEnterInputTag();
      }
    },
    handleEnterInputTag() {
      if (!this.inputTag) {
        return;
      }

      if (this.tags.includes(this.inputTag)) {
        this.inputTag = null;
        return;
      }

      this.tags.push(this.inputTag);
      this.$emit('input', this.tags);
      this.inputTag = null;
    },
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  background-color: #e9ecef;

  span.badge {
    opacity: 0.5;
  }
}
</style>
